import { Client } from '@twilio/conversations';
import { getEnv, getRoot } from 'mobx-easy';
import { makeAutoObservable, reaction, runInAction } from 'mobx';
import { Websocket, WebsocketEvents } from 'websocket-ts/lib';
import * as Sentry from '@sentry/browser';

import { RootEnv } from 'app/stores/config/CreateStore';
import { parsePagingResult } from 'app/models/CommonModels';
import { TelemedicineUrl } from 'app/models/Lead';
import {
  ActiveChatScreenData,
  ActiveConversationSid,
  ConversationActionType,
  DefaultConversations,
  IConversation,
  NewSurveyNotification,
  WebChatActiveScreen,
  UnreadMessagesCounter,
  WebchatUnreadMessagesCounter,
  WebConversationNewSpecialMsg,
  WebConversationsResult,
  WebConversationStage,
} from 'app/models/ChatModels';
import { filterByAttr } from 'app/utils/ArrayHelpers';
import { sortChatsByLastMessageDate } from 'app/utils/SortingHeplers';
import analytics from 'app/services/analytics';

import { WebConversation, WSMsg } from '../../../models/ChatModels';
import RootStore from '../../RootStore';
import ChatsStore from './Chats';
export class WebChats {
  chats: IConversation = DefaultConversations; //twilio retrived conversations
  webConversations: WebConversation[] = []; //rxdefine mirrored conversations
  wsNotificationMessage: WSMsg | null = null;
  rawData: WebConversationsResult;
  tempNewConv: WebConversation | null = null;
  tempStartConv: WebConversation | null = null;
  activeTab = WebConversationStage.New;
  typedMessages: Map<ActiveConversationSid, string> = new Map();
  busyStatus: '' | 'loadingRoster' = 'loadingRoster';
  telemedUrl: TelemedicineUrl[] = [];
  unreadMsgCount: WebchatUnreadMessagesCounter | null = null;
  activeChatScreen: WebChatActiveScreen | null = null;
  newPastedMessage = '';

  //private
  private webSocket: Websocket;
  private timerId;

  get isBusy() {
    return this.busyStatus === 'loadingRoster';
  }

  get activeChatStage(): WebConversationStage {
    const stage =
      this.webConversations.find((c) => c.conversation_sid === this.chats.activeConversationSid)?.stage ||
      WebConversationStage.New;

    return stage;
  }

  get hasUnread(): boolean {
    return this.webConversations.some((wc) => wc.unread_user_messages_count > 0);
  }

  constructor(private chatsStore: ChatsStore) {
    makeAutoObservable(this);
    const boundCallback = this.onWsMessage.bind(this);
    reaction(
      () => this.chatsStore.ws,
      (newWS, prevWS) => {
        if (prevWS) {
          prevWS.removeEventListener(WebsocketEvents.message, boundCallback);
          // @ts-ignore
          this.webSocket = null;
        }
        if (newWS) {
          newWS.addEventListener(WebsocketEvents.message, boundCallback);
          this.webSocket = newWS;
        }
      }
    );
    //setup communication channel if conversationalClient has updated
    reaction(
      () => chatsStore.conversationalClient,
      async () => {
        try {
          this.setupWebComunicationChannel(chatsStore.conversationalClient!);
        } catch (error) {
          const {
            uiStores: { notifierStore },
          } = getRoot<RootStore>();
          Sentry.captureException(error);
          notifierStore.notify("The chat list hasn't been loaded. Please try again later.");
          runInAction(() => {
            this.busyStatus = '';
          });
        }
      }
    );
  }

  closeWSNotifications() {
    if (!this.webSocket) {
      const { notificationsService } = getEnv<RootEnv>();
      this.webSocket = notificationsService.ws;
    }

    this.webSocket?.close();
  }

  setChats(newChats: IConversation) {
    this.chats = newChats;
  }

  setActiveChat(sid: string, viewOnly = false) {
    const rootStore = getRoot<RootStore>();
    // @ts-ignore
    const chat: WebConversation = this.webConversations
      // @ts-ignore
      .concat(this.tempStartConv)
      // @ts-ignore
      .concat(this.tempNewConv)
      .find((ch) => ch.conversation_sid === sid);
    if (!chat) {
      return;
    }

    this.unblockChat();
    if (!viewOnly) {
      chat.blocked = true;
      this.blockChat(sid);
    }

    this.changeChatStageBySid(sid, viewOnly ? WebConversationStage.Archived : WebConversationStage.InProgress);

    this.chats = {
      ...this.chats,
      activeConversationSid: sid,
      activeConversationJoinHistory: rootStore.dataStores.chatsStore.webChatStore.chatInfo.join_history,
    };
  }

  unblockChat(sid?: string) {
    if (this.timerId) {
      clearInterval(this.timerId);
      this.timerId = null;
    }

    const unblockingSid = sid || this.chats.activeConversationSid;
    if (unblockingSid) {
      const chat = this.chatBySid(unblockingSid);

      if (chat) {
        this.sendWsActionMsg(chat.uuid, ConversationActionType.Unblock);
        chat.blocked = false;
      }
    }

    this.chats.activeConversationSid = '';
    this.webConversations = [...this.webConversations];
  }

  setAllMsgsAsRead(sid: string) {
    const chat = this.chatBySid(sid);
    // @ts-ignore
    this.sendWsActionMsg(chat.uuid, ConversationActionType.SetMessagesRead);
    if (chat) {
      chat.unread_user_messages_count = 0;
      this.webConversations = [...this.webConversations];
    }
  }

  blockChat(sid: string) {
    const chat = this.chatBySid(sid);
    // @ts-ignore
    this.timerId = setInterval(() => this.sendWsActionMsg(chat.uuid, ConversationActionType.ProceedBlocking), 30000);
  }

  archiveConversation(sid: string): void {
    const chat = this.chatBySid(sid);
    this.setActiveTab(WebConversationStage.Archived);
    // @ts-ignore
    this.sendWsActionMsg(chat.uuid, ConversationActionType.Archive);
    this.changeChatStageBySid(sid, WebConversationStage.Archived);
  }

  setActiveTab = (newValue: WebConversationStage) => {
    this.activeTab = newValue;
  };

  private setupWebComunicationChannel(client: Client) {
    try {
      if (client) {
        client.on('conversationJoined', (twilioConversation) => {
          this.attachConversation(twilioConversation);
        });

        client.on('conversationLeft', (thisConversation) => {
          this.setChats({
            ...this.chats,
            conversations: [...this.chats.conversations.filter((it) => it !== thisConversation)],
          });
        });
      }
    } catch (error) {
      Sentry.captureException(error);
    }
  }

  private attachConversation(conversation) {
    if (conversation.friendlyName?.includes('web_')) {
      const chat = this.chats.conversations.find((c) => c.sid === conversation.sid);
      if (!chat) {
        this.chats.conversations.push(conversation);
      }
    }
  }

  private toggleChat(sid: string, blocked: boolean) {
    const chat = this.webConversations.find((ch) => ch.conversation_sid === sid);
    if (!chat) return;

    chat.blocked = blocked;
    this.webConversations = [...this.webConversations];
  }

  private changeChatStageBySid(sid: string, stage: WebConversationStage) {
    const chat = this.webConversations.find((ch) => ch.conversation_sid === sid);
    if (!chat) return;

    chat.stage = stage;
    this.webConversations = [...this.webConversations].filter((el) => el.stage === this.activeTab);
  }

  private onWsMessage(_i, ev) {
    const msg: WSMsg = JSON.parse(ev.data);

    this.onWebConversationWsMessage(msg);
    this.onNotificationWsMessage(msg);
  }

  private onWebConversationWsMessage(msg: WSMsg) {
    analytics.captureWsActionMsg(msg);
    if (msg?.source === 'web_conversation') {
      this.wsNotificationMessage = msg;
      switch (this.wsNotificationMessage.action) {
        case ConversationActionType.Block:
          this.toggleChat(this.wsNotificationMessage.conversation_sid, true);
          break;
        case ConversationActionType.Unblock:
          this.toggleChat(this.wsNotificationMessage.conversation_sid, false);
          break;
        case ConversationActionType.Archive:
          this.toggleChat(this.wsNotificationMessage.conversation_sid, true);
          this.changeChatStageBySid(this.wsNotificationMessage.conversation_sid, WebConversationStage.Archived);
          break;
        case ConversationActionType.NewMessage:
          this.handleNewMsg(msg);
          break;
        case ConversationActionType.DeliveryFailed:
          this.handleFailedDelivery(msg);
          break;
        case ConversationActionType.WebConversationSpecialAnswer:
          this.handleNewSpecialAnswer(msg);
          break;
        case ConversationActionType.UnblockConversation:
          this.toggleChat(this.wsNotificationMessage.conversation_sid, false);
          this.updateChatsOrdering(msg);
          break;
        case ConversationActionType.ChangeStatusFromNewToInProgres:
          this.changeChatStageBySid(msg.conversation_sid, WebConversationStage.InProgress);
      }
    } else {
      this.wsNotificationMessage = msg;
      if (this.wsNotificationMessage.action === ConversationActionType.UnreadMessagesCount) {
        this.setUnreadMessageCount(msg);
      }
    }
  }

  private onNotificationWsMessage(msg: WSMsg) {
    const {
      dataStores: {
        chatsStore: { webChatStore },
      },
    } = getRoot<RootStore>();

    if (msg?.action === ConversationActionType.UserOffline) {
      this.updateConvOnlineStatus(false, msg?.conversation_sid);
      webChatStore.setIsUserOnline(false);
    }

    if (msg?.action === ConversationActionType.UserOnline) {
      this.updateConvOnlineStatus(true, msg?.conversation_sid);
      webChatStore.setIsUserOnline(true);
    }

    if (msg?.action === ConversationActionType.ActiveChatScreen) {
      const data = msg.data as ActiveChatScreenData;

      webChatStore.setActiveChatScreen(data.active_chat_screen);
    }

    if (msg?.notification_type === 'new_survey') {
      const data = msg.data as NewSurveyNotification;

      if (data?.web_chat_uuid && webChatStore.chatInfo.uuid === data.web_chat_uuid) {
        webChatStore.setHasProfile = true;
      }
    }
  }

  setTempConversation(c: WebConversation) {
    this.tempStartConv = c;
  }

  cleanUpConversations() {
    this.webConversations = [];
  }

  async loadWebConversations(params?: string, loadNewList = false) {
    try {
      if (this.isBusy) return;
      this.busyStatus = 'loadingRoster';

      const { chatService } = getEnv<RootEnv>();
      const { data } = await chatService.loadWebConversations(params);
      const rawData: WebConversationsResult = parsePagingResult(data);
      const results = rawData.results;

      runInAction(() => {
        const temp: WebConversation[] = [];
        //in progress
        if (
          this.activeTab === WebConversationStage.InProgress &&
          this.tempStartConv &&
          results.every((c) => c.conversation_sid !== this.tempStartConv?.conversation_sid)
        ) {
          temp.push(this.tempStartConv);
        }
        this.tempStartConv = null;

        this.webConversations = loadNewList ? results : [...temp, ...this.webConversations, ...results];

        const filtererArray = filterByAttr([...this.webConversations], 'conversation_sid');

        this.webConversations = [...filtererArray];

        this.rawData = rawData;
        this.busyStatus = '';
      });
    } catch (error) {
      throw Error();
    } finally {
      runInAction(() => (this.busyStatus = ''));
    }
  }

  private handleNewMsg(msg: WSMsg) {
    const webConversationMsg = msg.data as WebConversation;

    const conv = this.webConversations.find((c) => webConversationMsg?.conversation_sid === c.conversation_sid);

    if (conv && conv.stage === WebConversationStage.Archived) {
      this.webConversations = [...this.webConversations.filter((c) => c.conversation_sid !== conv.conversation_sid)];

      if (conv.conversation_sid === this.chats.activeConversationSid) {
        this.unblockChat();
        this.setActiveTab(WebConversationStage.New);
      }
    }

    this.updateChatsOrdering(msg);
  }

  private handleFailedDelivery(msg: WSMsg) {
    const webConversationMsg = msg.data as WebConversation;

    const conv = this.webConversations.find((c) => webConversationMsg.conversation_sid === c.conversation_sid);

    if (conv) {
      conv.delivery_failed = true;
      this.webConversations = [...this.webConversations];
    }
  }

  private handleNewSpecialAnswer(msg: WSMsg) {
    const webConversationNewSpecialMsg = {
      ...msg.data,
      conversation_sid: msg.conversation_sid,
    } as WebConversationNewSpecialMsg;
    const {
      dataStores: {
        chatsStore: { webChatStore },
      },
    } = getRoot<RootStore>();

    const conv = this.webConversations.find(
      (c) => webConversationNewSpecialMsg.conversation_sid === c.conversation_sid
    );

    if (conv) {
      webChatStore.refreshSpecialAnswersBySid(webConversationNewSpecialMsg.conversation_sid);
      this.webConversations = [...this.webConversations];
    }
  }

  private chatBySid(sid: string) {
    return this.webConversations.find((c) => c.conversation_sid === sid);
  }

  private sendWsActionMsg(conversationUUID: string, action: ConversationActionType) {
    const {
      dataStores: { authStore },
    } = getRoot<RootStore>();

    this.webSocket.send(
      JSON.stringify({
        action: 'web_conversation_' + action,
        conversation_uuid: conversationUUID,
        cdp_auth: authStore.authData.access,
      })
    );
  }

  setTypedMessages(msg: string) {
    this.typedMessages.set(this.chats.activeConversationSid, msg);
  }

  setNewPastedMessage(message: string) {
    this.newPastedMessage = message;
  }

  get activeTypedMsg() {
    return this.typedMessages.get(this.chats.activeConversationSid);
  }

  deleteActiveTypedMsg() {
    this.typedMessages.delete(this.chats.activeConversationSid);
  }

  async fetchTelemedicineUrl(uuid: string) {
    try {
      const { leadService } = getEnv<RootEnv>();
      const { data } = await leadService.getWebTelemedicineUrl(uuid);

      runInAction(() => (this.telemedUrl = data));
    } catch (error) {
      const {
        uiStores: { notifierStore },
      } = getRoot<RootStore>();
      notifierStore.notify(error?.message);
    }
  }

  updateWebConversationTestOnly(id: string, testOnly: boolean) {
    const predicate = ({ uuid }: { uuid: string }) => uuid === id;
    const targetItem = this.webConversations.find(predicate);
    const targetItemIndex = this.webConversations.findIndex(predicate);

    // @ts-ignore
    this.webConversations.splice(targetItemIndex, 1, {
      ...targetItem,
      test_only: testOnly,
    });
  }

  updateChatsOrdering(msg: WSMsg) {
    const webConversationMsg = msg.data as WebConversation;

    const conv = this.webConversations.find((c) => webConversationMsg?.conversation_sid === c.conversation_sid);
    if (conv) {
      const newArray = this.webConversations.map((obj) => {
        if (obj.conversation_sid === webConversationMsg.conversation_sid) {
          return webConversationMsg;
        } else {
          return obj;
        }
      });

      this.webConversations = [...newArray].sort(sortChatsByLastMessageDate);
    } else {
      runInAction(() => {
        if (this.activeTab === webConversationMsg.stage) {
          this.webConversations = [webConversationMsg, ...this.webConversations];
        }
      });
    }
  }

  sortChatsOnConciergeMsg() {
    const conv = this.webConversations.find((c) => this.chats?.activeConversationSid === c.conversation_sid);
    if (conv) {
      conv.last_message_dt = new Date().toISOString();
      this.webConversations = [...this.webConversations].sort(sortChatsByLastMessageDate);
    }
  }

  setUnreadMessageCount(msg) {
    const {
      dataStores: { userStore },
    } = getRoot<RootStore>();
    runInAction(() => {
      const counterData = msg.data.find((obj) => obj[userStore.userUUID]) as UnreadMessagesCounter;

      if (counterData) {
        this.unreadMsgCount = counterData[userStore.userUUID].web_chats;
      }
    });
  }

  get newUnreadMsgCount() {
    return this.unreadMsgCount?.new;
  }

  get inProgressUnreadMsgCount() {
    return this.unreadMsgCount?.in_progres;
  }

  updateConvOnlineStatus(isOnline: boolean, sid: string) {
    const conv = this.webConversations.find((c) => sid === c.conversation_sid);
    if (conv) {
      conv.user_online = isOnline;
    }
  }
}
