/* eslint-disable @typescript-eslint/no-explicit-any */
import { action, makeAutoObservable, observable, runInAction } from 'mobx';
import { getEnv, getRoot } from 'mobx-easy';
import squareicon from 'squareicon';

import {
  ProfileLinkModel,
  WebChatActiveScreen,
  WebConversation,
  WebConversationDetails,
  WebConversationStage,
} from 'app/models/ChatModels';
import { basicIconConfig, PaginatorTwilioMesssages } from 'app/models/CommonModels';
import { RootEnv } from 'app/stores/config/CreateStore';
import { sortChatMessagesByDate } from 'app/utils/SortingHeplers';
import { ConsumerType } from 'app/models/LeadsModels';
import { insertObjectsBetweenDates } from 'app/utils/ArrayHelpers';

import RootStore from '../../RootStore';

export interface IChat {
  conversationProxy: any;
  messages: any[];
  loadingState: string;
  boundConversations: Set<any>;
  messagePaginator: PaginatorTwilioMesssages<any>;
}

export class WebChat {
  chat: IChat = {
    conversationProxy: null,
    messages: [],
    loadingState: 'initializing',
    boundConversations: new Set<[]>(),
    // @ts-ignore
    messagePaginator: null,
  };
  messages: any[] = [];
  chatInfo: WebConversationDetails = {
    uuid: '',
    conversation_sid: '',
    chat_service_sid: '',
    stage: WebConversationStage.InProgress,
    blocked: true,
    contact_info: '',
    join_history: [],
    special_answers: [],
    condition_name: '',
    local_time: '',
    imageSrc: '',

    friendly_name: '',
    last_message_dt: '',
    last_consumer_message_dt: '',
    unread_user_messages_count: 0,
    delivery_failed: false,
    has_profile: false,
    test_only: false,
    send_telemedicine_link: false,
    patient_email: '',
    survey_uuid: '',
    product_name: '',
    token_user: '',
    consumer_type: ConsumerType.CONSUMER,
    active_chat_screen: null,
    user_online: false,
    logo: '',
    condition_uuid: '',
    auto_archive_disabled: false,
  };
  typingMsg: any = null;
  isLoading = false;

  constructor() {
    makeAutoObservable(this, {
      chat: observable,
      messages: observable.ref,
      setChat: action,
    });
  }

  set setHasProfile(hasProfile: boolean) {
    this.chatInfo.has_profile = hasProfile;
  }

  changeStage(stage: WebConversationStage) {
    this.chatInfo.stage = stage;
  }

  setChat(newChat: IChat) {
    const joiningMessages = this.chatInfo.join_history || [];

    this.chat = newChat;
    this.messages = insertObjectsBetweenDates([...this.chat.messages, ...joiningMessages].sort(sortChatMessagesByDate));
  }

  setMessages(messages: any[]) {
    this.messages = messages;
  }

  setTypingState(value: any) {
    this.typingMsg = value;
  }

  async startWebConversation(
    uuid: string,
    sid?: string
  ): Promise<{ success: boolean; webConversation: WebConversation }> {
    try {
      const { chatService } = getEnv<RootEnv>();
      const {
        dataStores: { chatDocumentsStore, chatsStore, authStore },
      } = getRoot<RootStore>();

      const response = await chatService.startWebConversationByUuid(uuid);

      const token = response.data?.token_user;

      await chatsStore.openCommunicationsChannel(token);
      await chatDocumentsStore.loadDocuments(uuid, true);
      if (authStore.isConcierge) {
        await chatsStore.webChatsStore.fetchTelemedicineUrl(uuid);
      }

      const { data: rawData } = await (uuid
        ? chatService.getWebConversationUuid(uuid)
        : // @ts-ignore
          chatService.getWebConversationBySID(sid));

      if (rawData.active_chat_screen) {
        this.setActiveChatScreen(rawData.active_chat_screen);
      }

      // @ts-ignore
      const data = {
        ...rawData,
        join_history: Object.keys(rawData.join_history).map((key) => ({
          joiningDate: new Date(key),
          identityName: rawData.join_history[key],
        })),
        imageSrc: null,
      } as WebConversationDetails;

      squareicon({ id: data.conversation_sid, ...basicIconConfig }, (_err, img) => {
        data.imageSrc = img;
      });

      runInAction(() => {
        this.chatInfo = data;
      });

      return { success: true, webConversation: response.data };
    } catch (error) {
      console.dir(error);
      throw new Error(error?.response?.data?.message);
    }
  }

  async refreshSpecialAnswersBySid(sid: string) {
    try {
      const { chatService } = getEnv<RootEnv>();
      const { data: rawData } = await chatService.getWebConversationBySID(sid);
      const { special_answers } = rawData;

      runInAction(() => {
        this.chatInfo.special_answers = special_answers;
      });
    } catch (error) {
      console.dir(error);
    }
  }

  async getProfileLink(uuid: string): Promise<ProfileLinkModel> {
    try {
      const { chatService } = getEnv<RootEnv>();
      const { data: profileInfo } = await chatService.getProfileLink(uuid);
      return profileInfo;
    } catch (error) {
      throw new Error();
    }
  }

  async editWebChat(editData: unknown) {
    this.isLoading = true;

    try {
      const { chatService } = getEnv<RootEnv>();
      const { data } = await chatService.editWebChat(this.chatInfo.uuid, editData);

      const {
        dataStores: { chatsStore },
      } = getRoot<RootStore>();

      runInAction(() => {
        this.chatInfo = {
          ...data,
          join_history: Object.keys(data.join_history).map((key) => ({
            joiningDate: new Date(key),
            identityName: data.join_history[key],
          })),
        };
      });

      // @ts-ignore
      if (Object.keys(editData)[0] === 'test_only') {
        // @ts-ignore
        await chatsStore.webChatsStore.updateWebConversationTestOnly(this.chatInfo.uuid, editData['test_only']);
      }
    } catch (error) {
      throw new Error();
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  }

  setActiveChatScreen(newActiveScreen: WebChatActiveScreen) {
    this.chatInfo.active_chat_screen = newActiveScreen;
  }

  setIsUserOnline(isOnline: boolean) {
    this.chatInfo.user_online = isOnline;
  }

  setTestOnly(testOnly: boolean) {
    this.chatInfo.test_only = testOnly;
  }
}
